import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Payment1 from "assets/images/payment/1.png"
import Payment2 from "assets/images/payment/2.png"
import Payment3 from "assets/images/payment/3.png"
import Payment4 from "assets/images/payment/4.png"
import Payment5 from "assets/images/payment/5.png"
import Payment6 from "assets/images/payment/6.png"
import Payment7 from "assets/images/payment/7.png"
import Payment8 from "assets/images/payment/8.png"
import Payment9 from "assets/images/payment/9.png"
import Payment10 from "assets/images/payment/10.png"
import Payment11 from "assets/images/payment/11.png"
import Payment12 from "assets/images/payment/12.png"
import Payment13 from "assets/images/payment/13.png"

const FooterLinksData = [
  {
    title: "About",
    links: [
      {
        text: "Us vs Them",
        path: "/us-vs-them/",
      },
      {
        text: "Our Story",
        path: "https://shop.kissmyketo.com/pages/ourketostory",
      },
      {
        text: "Our Writers",
        path: "https://blog.kissmyketo.com/about/",
      },
    ],
  },
  {
    title: "Shop",
    links: [
      {
        text: "Gift Card",
        path: "/kiss-my-keto-gift-card/",
      },
      {
        text: "Keto Snacks",
        path: "/keto-snacks/",
      },
      {
        text: "Keto Foods",
        path: "/keto-foods/",
      },
      {
        text: "Keto Supplements",
        path: "/keto-supplements/",
      },
    ],
  },
  {
    title: "Orders",
    links: [
      {
        text: "My Account",
        path: "https://shop.kissmyketo.com/account/login",
      },
      {
        text: "How subscriptions work",
        path: "https://shop.kissmyketo.com/pages/subscription-faq",
      },
      {
        text: "Return, FAQ, Shipping",
        path: "/return-faq-shipping/",
      },
      {
        text: "Track My Order",
        path: "https://tracking.kissmyketo.com/",
      },
    ],
  },
  {
    title: "Wholesale",
    links: [
      {
        text: "Wholesale Portal",
        path: "https://wholesale.kissmyketo.com/",
      },
      {
        text: "Store Locator",
        path: "https://shop.kissmyketo.com/pages/store-locator",
      },
    ],
  },
  {
    title: "Rewards",
    links: [
      {
        text: "Earn Points",
        path: "https://shop.kissmyketo.com/pages/rewards",
      },
      {
        text: "Refer a friend",
        path: "https://shop.kissmyketo.com/pages/refer",
      },
      {
        text: "Ambassadors",
        path: "https://shop.kissmyketo.com/pages/ambassadors",
      },
    ],
  },
  {
    title: "Learn",
    links: [
      {
        text: "Ketocademy",
        path: "https://blog.kissmyketo.com/ketocademy/",
      },
      {
        text: "Keto Blog",
        path: "https://blog.kissmyketo.com/articles/",
      },
      {
        text: "Recipes",
        path: "https://blog.kissmyketo.com/recipes/",
      },
      {
        text: "Help Center",
        path: "https://help.kissmyketo.com/hc/en-us/",
      },
      {
        text: "Keto FAQs",
        path: "https://blog.kissmyketo.com/keto-faq/",
      },
      {
        text: "Cookie Policy",
        path: "https://shop.kissmyketo.com/pages/cookie-policy",
      },
    ],
  },
]

const FooterLinks = ({ title, links }) => {
  const [isLinkGroupOpened, setIsLinkGroupOpened] = useState()

  return (
    <div className="footer-links">
      <div
        className={isLinkGroupOpened ? "footer-title active" : "footer-title"}
        onClick={() => setIsLinkGroupOpened(!isLinkGroupOpened)}
      >
        <span className="link-plus-minus-icon"></span>
        {title}
      </div>
      <div className="footer-links-mode">
        {links.map((link, index) => {
          return (
            <div className="footer-link" key={`flm-${index}`}>
              {link.path.includes("https") || link.path.includes("http") ? (
                <a href={link.path} className="link">
                  {link.text}
                </a>
              ) : (
                <Link to={link.path} className="link">
                  {link.text}
                </Link>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Footer = () => {
  return (
    <footer className="section footer section-wrapper">
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer-other-links">
            {FooterLinksData.map((groupLinks, index) => (
              <FooterLinks title={groupLinks.title} links={groupLinks.links} index={index} key={index} />
            ))}
          </div>
          <div className="footer-other-contact">
            <div className="footer-other-contact-list">
              <div className="footer-title">Contact</div>
              <div className="contact-list">
                <a href="mailto:hello@kissmyketo.com">
                  <i className="icon-envelope"></i> hello@kissmyketo.com
                </a>
              </div>
              <div className="contact-list">
                <i className="icon-map"></i> 8066 Melrose Avenue, Suite 3 <br /> Los Angeles, CA 90046
              </div>
            </div>
            <div className="footer-other-contact-list">
              <div className="footer-title">Follow us</div>
              <div className="footer-social">
                <a href="https://twitter.com/kissmyketo" className="footer-social-link" target="_blank">
                  <StaticImage src="../../../assets/images/footer/twitte.png" alt="twitter logo" />
                </a>
                <a href="https://www.facebook.com/kissmyketo/" className="footer-social-link" target="_blank">
                  <StaticImage src="../../../assets/images/footer/facebook.png" alt="facebook logo" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC7F0kPuRJhBW9fCVDgaglBQ"
                  className="footer-social-link"
                  target="_blank"
                >
                  <StaticImage src="../../../assets/images/footer/youtube.png" alt="youtube logo" />
                </a>
                <a href="https://www.instagram.com/kissmyketo/" className="footer-social-link" target="_blank">
                  <StaticImage src="../../../assets/images/footer/instagram.png" alt="instagram logo" />
                </a>
                <a href="https://www.pinterest.ca/kissmyketopins/" className="footer-social-link" target="_blank">
                  <StaticImage src="../../../assets/images/footer/pinterest.png" alt="pinterest logo" />
                </a>
              </div>
            </div>
            <div className="footer-other-contact-list">
              <div className="footer-title">Accepted Payments</div>
              <div className="footer-payment">
                <div className="foo-payment-wrappter">
                  <div className="foo-payment">
                    <img src={Payment1} className="pay-img" width="37" height="24" alt="Visa" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment2} className="pay-img" width="37" height="24" alt="Matercard" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment3} className="pay-img" width="37" height="24" alt="Amex" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment4} className="pay-img" width="37" height="24" alt="JCB" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment5} className="pay-img" width="37" height="24" alt="Discover" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment6} className="pay-img" width="37" height="24" alt="Payment" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment7} className="pay-img" width="37" height="24" alt="Elo" />{" "}
                  </div>
                </div>
                <div className="foo-payment-wrappter">
                  <div className="foo-payment">
                    <img src={Payment8} className="pay-img" width="37" height="24" alt="Google Pay" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment9} className="pay-img" width="37" height="24" alt="Apple Pay" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment10} className="pay-img" width="37" height="24" alt="Pay" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment11} className="pay-img" width="37" height="24" alt="Amazon Pay" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment12} className="pay-img" width="37" height="24" alt="Paypal" />{" "}
                  </div>
                  <div className="foo-payment">
                    <img src={Payment13} className="pay-img" width="37" height="24" alt="Clearpay" />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-policy">
            <div className="footer-policy-item">
              <div className="">© 2022 Kiss My Keto.</div>
            </div>
            <div className="footer-policy-item">
              <a href="https://shop.kissmyketo.com/pages/privacy-policy" target="_blank" className="policy-link">
                Privacy Policy
              </a>
              <a href="https://shop.kissmyketo.com/pages/disclaimer" target="_blank" className="policy-link">
                Disclaimer
              </a>
            </div>
          </div>
          <div className="footer-disclaimer text-center">
            Disclaimer: These statements have not been evaluated by the Food and Drug Administration. These products are
            not intended to diagnose, treat, cure or prevent any disease. <br />
            Product results may vary from person to person.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
