import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { StoreContext } from "context/store"
import SearchBox from "components/molecules/SearchBox"
import CartModal from "components/molecules/CartModal"
import BannerText from "components/molecules/BannerText/BannerText"
import BlackFriday from "components/molecules/BlackFriday/BlackFriday"
import HeaderMobileLeftMenu from "components/molecules/HeaderMobileLeftMenu"

import { blackFridayTimer } from "consts/helper"
import useDocumentScrollThrottled from "hooks/useDocumentScrollThrottled"

const Header = ({ banner, setHideNav }) => {
  const { lineItems, setIsOpenCart, isBlackFri = "false", setIsBlackFri, setHeaderShow } = useContext(StoreContext)
  const { bannerText, countdownDate } = banner
  const [isMounseInHeader, setIsMouseInHeader] = useState(false)
  const [shouldHideHeader, setShouldHideHeader] = useState(false)
  const [searchBarOpened, setSearchBarOpened] = useState(0)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(0)
  const [countDown, setCountDown] = useState({})

  useEffect(() => {
    if (isBlackFri && countdownDate) {
      const interval = setInterval(() => {
        const countDown = blackFridayTimer(countdownDate)
        if (countDown?.distance < 0) {
          setIsBlackFri(false)
        } else {
          setCountDown(countDown)
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [isBlackFri])

  const MINIMUM_SCROLL = 128
  const TIMEOUT_DELAY = 150
  const SEARCH_INDICES = [
    { name: `Pages`, title: `Pages` },
    { name: `gatsby_products`, title: `Products` },
    { name: `wp_posts_recipescentre`, title: `Recipes` },
    { name: `wp_posts_keto-articles`, title: `Articles` },
  ]

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData
    const isScrolledDown = previousScrollTop < currentScrollTop
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled)
    }, TIMEOUT_DELAY)
  })

  const hiddenHeaderStyle = !isMounseInHeader && shouldHideHeader ? "main-header-hidden" : ""
  const brStyle = { marginTop: 0 }

  useEffect(() => {
    setHeaderShow(shouldHideHeader)
    setHideNav(!isMounseInHeader && shouldHideHeader)
  }, [hiddenHeaderStyle])

  const categories = [
    {
      title: "Keto Gummies",
      slug: "/shop-keto-gummies/",
    },
    {
      title: "Keto Snacks",
      slug: "/keto-snacks/",
    },
    {
      title: "Keto Foods",
      slug: "/keto-foods/",
    },
    {
      title: "Keto Bread",
      slug: "/keto-bread/bread-variety-pack/",
    },
    {
      title: "Keto Supplements",
      slug: "/keto-supplements/",
    },
    // {
    //   title: "Deals",
    //   slug: "/deals-of-the-week/",
    // },
  ]

  return (
    <div>
      {/* Black Friday Top Sticker */}
      {isBlackFri && <BlackFriday setIsBlackFri={setIsBlackFri} banner={banner} countDown={countDown} />}
      <header
        className={`main-header ${hiddenHeaderStyle}`}
        style={!isBlackFri ? brStyle : null}
        onMouseEnter={() => setIsMouseInHeader(true)}
        onMouseLeave={() => setIsMouseInHeader(false)}
      >
        {/* Free Shipping Top Sticker */}
        <BannerText bannerText={bannerText} isBlackFriday={isBlackFri} />
        <div className={`header-nav`}>
          <button className="btn-menu" id="btnMenu" onClick={() => setIsMobileMenuOpen(1)}>
            <i className="h-icon-humberg"></i>
          </button>
          <div className="col1 logo-col">
            <Link to="/" className="logo-link">
              <StaticImage src="../../../assets/images/logo.svg" alt="logo" loading="eager" placeholder="kissmyketo" />
            </Link>
          </div>
          <div className="col2 nav-col">
            <ul className={searchBarOpened ? "nav-list visibility-hidden" : "nav-list"}>
              <li className="nav-item">
                <Link className="nav-link" to="/all/">
                  Shop All
                </Link>
              </li>
              {categories.map((category, idx) => (
                <li className="nav-item" key={category.title}>
                  <Link className={`nav-link`} to={category.slug}>
                    {category.title}
                  </Link>
                </li>
              ))}
              <li className="nav-item">
                <Link to="/reviews/" className="nav-link">
                  Reviews
                </Link>
              </li>
              <li className="nav-item">
                <a href="https://shop.kissmyketo.com/pages/refer" target="_blank" rel="noreferrer" className="nav-link">
                  Refer a Friend
                </a>
              </li>
            </ul>
          </div>
          <div className="col3 cart-col">
            <div className="cart-area">
              <div className="cart-nav-item search-item">
                <a href="#" className="ct-link" onClick={() => setSearchBarOpened(1)}>
                  <i className="h-icon-search"></i>
                </a>
                <div className={searchBarOpened ? "search-group active" : "search-group"}>
                  <SearchBox indices={SEARCH_INDICES} />

                  <button className="close-btn" onClick={() => setSearchBarOpened(0)}>
                    <i className="h-icon-cancel"></i>
                  </button>
                </div>
              </div>
              <div className="cart-nav-item map-item">
                <a href="https://shop.kissmyketo.com/pages/store-locator" className="ct-link">
                  <i className="h-icon-map"></i>
                </a>
              </div>
              <div className="cart-nav-item users-item">
                <a href="https://shop.kissmyketo.com/account/login" className="ct-link">
                  <i className="h-icon-users"></i>
                </a>
              </div>
              <div className="cart-nav-item addto-cart-item">
                <div className="ct-link cart-link" onClick={() => setIsOpenCart(true)}>
                  <i className="h-icon-addcart"></i>
                  {lineItems?.length ? <span className="badge">{lineItems.length}</span> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        <HeaderMobileLeftMenu open={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(0)} categories={categories} />
      </header>
      <CartModal headerHide={!isMounseInHeader && shouldHideHeader} />
    </div>
  )
}

export default Header
